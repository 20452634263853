import { likeRegExp } from '@/offline/database'

export default {
  _selectSistemaMantBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.sistema_mant)
      .innerJoin(
        tables.periodo_revision,
        tables.sistema_mant.idperiodo_revision.eq(tables.periodo_revision.idperiodo_revision)
      )
      .innerJoin(
        tables.tactuacion,
        tables.sistema_mant.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .orderBy(tables.sistema_mant.fplanificacion)
  },
  async selectSistemaMant (Vue, filter, search, sorter, page, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.tactuacion.descripcion.match(reQ),
          tables.periodo_revision.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.sistema_mant.idsistema.eq(idsistema),
        tables.sistema_mant.estado.gt(0),
        tables.sistema_mant.activar.eq(true),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectSistemaMantBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectSistemaMantRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectSistemaMantBase(Vue)
      .where(tables.sistema_mant.idsistema_mant.in(pks))
      .exec()
  }
}
